import { useEffect, useState } from 'react';
import { useOfficeStore } from '../../officeStore';
import { base64toFile } from '../../utils/fileUtils';
import { useDocumentsStore, useEmlStore } from './store';
import { Res, useFetch } from 'use-http';
import { DocumentView, MessagesResponse } from './types';
import { AttachmentResponse } from '../attachments';
import { Md5 } from 'ts-md5';
import * as Sentry from '@sentry/react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { sleep } from '../../utils/asyncUtils';

const getMessagesUrl = (conversationId: string) => {
  return `${Office.context.mailbox.restUrl}/v2.0/me/messages?select=ConversationId,Subject & $filter=ConversationId eq '${conversationId}'`;
};

export const useAttachmentDocuments = ({
  token,
  conversationId,
  updateLoading,
}: {
  token: string;
  conversationId: string;
  updateLoading: (loading: boolean) => void;
}): {
  attachmentDocuments: DocumentView[];
} => {
  const { documents: attachmentDocuments, addDocument } = useDocumentsStore();

  const { addFile } = useEmlStore();
  const [finishedFetching, setFinishedFetching] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const reset = () => {
    setFinishedFetching(false);
    setIsFetching(false);
  };

  useEffect(() => {
    reset();
  }, [conversationId]);

  const isOfficeInitialized = useOfficeStore(
    state => state.isOfficeInitialized,
  );

  const [messageBaseUrl, setMessageBaseUrl] = useState<string>();
  const [messagesUrl, setGetMessagesUrl] = useState<string>();

  const getRestId = (ewsId: string) => {
    return Office.context.mailbox.convertToRestId(
      ewsId,
      Office.MailboxEnums.RestVersion.v2_0,
    );
  };

  const requestHeaderInterceptor = ({ options }: { options: RequestInit }) => {
    options.headers['Authorization'] = 'Bearer ' + token;
    return options;
  };

  const responseInterceptor = async <FetchResponse,>({
    response,
  }: {
    response: Res<FetchResponse>;
  }) => {
    if (response.status == 403 || response.status == 401) {
      const newToken: string = await new Promise(resolve => {
        Office.context?.mailbox?.getCallbackTokenAsync(
          { isRest: true },
          result => resolve(result.value),
        );
      });
      token = newToken;
    }
    return response;
  };

  const { get: getMessages, response: getMessagesResponse } =
    useFetch<MessagesResponse>(messagesUrl, {
      interceptors: {
        request: requestHeaderInterceptor,
        response: responseInterceptor<MessagesResponse>,
      },
      retries: 2,
      retryOn: [401],
    });

  const { get: getMessage, response: getEmlResponse } = useFetch(
    messageBaseUrl,
    {
      interceptors: {
        request: requestHeaderInterceptor,
        response: responseInterceptor,
      },
    },
  );

  useEffect(() => {
    if (!isOfficeInitialized || !Office.context?.mailbox) return;
    const restUrl = Office.context.mailbox.restUrl;
    const msgsUrl = getMessagesUrl(getRestId(conversationId));
    setGetMessagesUrl(msgsUrl);
    setMessageBaseUrl(restUrl);
  }, [isOfficeInitialized, conversationId]);

  const intl = useIntl();

  useEffect(() => {
    const getMessageEmlAsync = async (
      subject: string,
      restId: string,
      isLastMessage: boolean,
    ) => {
      const emlPath = `/v2.0/me/messages/${restId}/$value`;
      const emlFile: Blob = (await getMessage(emlPath)) as Blob;
      if (getEmlResponse.ok) {
        const fileName = (subject || 'the email message') + ' (EML).eml';
        // const file = textToFile(fileName, emlFile);
        const f = new File([emlFile], fileName, { type: 'text/plain' });
        addFile(f);
        if (isLastMessage) {
          addDocument({
            id: f.name,
            title: intl.formatMessage(messages.uploadEmailAsZipEml),
            content: null,
            isSelected: true,
            attachment: false,
            invalidFiletype: false,
            md5: '',
          });
        }
      }
    };

    const getMessageAttachmentsAsync = async (restId: string) => {
      const attachmentPath = `/v2.0/me/messages/${restId}/attachments`;
      const attachments: AttachmentResponse = (await getMessage(
        attachmentPath,
      )) as AttachmentResponse;

      const validFileEndings: string[] = [
        'pdf',
        'docx',
        'ppsx',
        'tif',
        'jpg',
        'jpeg',
        'png',
        'xlsx',
        'html',
        'gif',
        'txt',
      ];

      if (getMessagesResponse.ok && attachments.value) {
        Sentry.addBreadcrumb({
          category: 'attachments',
          message:
            'Number of attachmets found ' +
            attachments?.value?.length.toString(),
          level: 'info',
        });

        attachments.value.forEach(attachment => {
          const validType = validFileEndings.includes(
            attachment.Name.split('.').pop(),
          );

          if (attachment.ContentType.startsWith('message/')) {
            return;
          }
          const doc: DocumentView = {
            id: attachment.Id,
            title: attachment.Name,
            content:
              attachment.ContentBytes &&
              base64toFile(attachment.ContentBytes, attachment.Name),
            isSelected: validType,
            attachment: true,
            invalidFiletype: !validType,
            md5:
              attachment.ContentBytes && Md5.hashStr(attachment.ContentBytes),
          };
          addDocument(doc);
        });
      } else {
        Sentry.captureException(
          new Error(getMessagesResponse.status.toString()),
          {
            tags: {
              section: 'getMessageAttachmentsAsync',
            },
          },
        );
      }
    };

    const getMessagesAsync = async () => {
      updateLoading(true);
      setIsFetching(true);
      const messages = await getMessages();

      if (getMessagesResponse.ok) {
        const uniqueRestIds = new Set<string>();
        const subjects: {
          subject: string;
          restId: string;
        }[] = [];

        messages.value.forEach(message => {
          const restId = getRestId(message.Id);
          uniqueRestIds.add(restId);
          subjects.push({
            subject: message.Subject,
            restId,
          });
        });

        const buildEmailDataPromises = async () => {
          const emailDataPromises: Promise<unknown>[] = [];

          for (let i = 0; i < subjects.length; i++) {
            await sleep(75);
            const { subject, restId } = subjects[i];
            const isLastMessage = i === subjects.length - 1;
            emailDataPromises.push(
              getMessageEmlAsync(subject, restId, isLastMessage),
            );
          }

          for (const restId of uniqueRestIds) {
            await sleep(75);
            emailDataPromises.push(getMessageAttachmentsAsync(restId));
          }

          return emailDataPromises;
        };

        const emailDataPromises = await buildEmailDataPromises();

        try {
          await Promise.all(emailDataPromises);
        } catch (error) {
          Sentry.captureException(error, {
            tags: {
              section: 'getMessagesAsync',
            },
          });
        }

        updateLoading(false);
        setIsFetching(false);
        setFinishedFetching(true);
      }
    };

    if (messagesUrl && messageBaseUrl && !finishedFetching && !isFetching) {
      void getMessagesAsync();
    }
  }, [
    addDocument,
    addFile,
    getEmlResponse.ok,
    getMessage,
    getMessages,
    getMessagesResponse.ok,
    getMessagesResponse.status,
    messageBaseUrl,
    messagesUrl,
    updateLoading,
    intl,
    finishedFetching,
    isFetching,
  ]);

  return { attachmentDocuments };
};
