import { StateRouter } from '../app/stateRouter/StateRouter';
import { StateRoute } from '../app/stateRouter/StateRoute';
import { NewProjectPage } from './Projects/New/NewProject';
import { NewProjectSuccess } from './Projects/New/Success/NewProjectSuccess';
import { NavHeader } from '../app/NavHeader';

export const Taskpane = () => {
  return (
    <StateRouter initialRoute="/projects/new">
      <NavHeader />
      <StateRoute path="/projects/new">
        <NewProjectPage />
      </StateRoute>
      <StateRoute path="/projects/new/success">
        <NewProjectSuccess />
      </StateRoute>
    </StateRouter>
  );
};
