import {
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import { Project } from './types';
import { DocumentView } from '../documents';
import { useEmlStore } from '../documents/store';
import useFetch from 'use-http';
import { API_BASE_URL, APP_BASE_URL } from '../../config';
import { Link } from '@fluentui/react-components';
import JSZip from 'jszip';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

type DraftProps = {
  //  logo: string;
  //  message: string;
  title: string;
  orgId: string | number;
  templateId: string | number;
  conversationId: string;
  files: DocumentView[];
  updateLoading: (enabled: boolean) => void;
};

function lpad(ix: number, padding: number): string {
  const zeroes = new Array(padding + 1).join('0');
  const prefix = ('' + zeroes + ix.toString()).slice(-padding);
  return prefix;
}

export const Draft = (params: DraftProps) => {
  const [project, setProject] = useState<Project>();
  const [projectLink, setProjectLink] = useState<string>();
  const { orgId, templateId, conversationId, files } = params;
  const [errorMsg, setErrorMsg] = useState<string>();
  const [importedDone, setImportedDone] = useState<boolean>(false);
  const [supportingDone, setSupportingDone] = useState<boolean>(false);

  const draftURL =
    API_BASE_URL() + `/organizations/${orgId}/templates/${templateId}/projects`;
  const draftFetch = useFetch<Project>(draftURL);

  const importURL =
    API_BASE_URL() +
    `/organizations/${orgId}/projects/${project?.id}/documents/imports`;

  const {
    post: postImportedDocuments,
    response: importedDocumentsResponse,
    error: errorImportedDocuments,
  } = useFetch<Document[]>(importURL);

  const supportingDocsURL =
    API_BASE_URL() +
    `/organizations/${orgId}/projects/${project?.id}/supporting-files`;

  const {
    post: postSupportingDocuments,
    response: supportingDocsResponse,
    error: errorSupportingDocuments,
  } = useFetch<Document[]>(supportingDocsURL);
  const { files: emlFiles } = useEmlStore();

  useEffect(() => {
    async function zipEMLFiles(emlFiles: File[]) {
      const files = [...emlFiles].reverse();
      const zip = new JSZip();
      files.forEach((file, index) => {
        const name = lpad(index + 1, 3) + '_' + file.name;
        zip.file(name, file);
      });

      const zipStream = await zip.generateAsync({ type: 'blob' });
      return new File([zipStream], 'EML files.zip');
    }

    async function createFormdataImportedDocuments(documents: DocumentView[]) {
      const data = new FormData();
      const dataParts = await Promise.all(
        documents
          .filter(doc => doc.attachment)
          .map(document => {
            return document.content;
          }),
      );

      dataParts.forEach(part => {
        data.append('file', part, part.name);
      });
      return data;
    }

    async function createFormdataSupportingDocuments(
      documents: DocumentView[],
      emlFiles: File[],
    ) {
      const data = new FormData();
      const part = await zipEMLFiles(emlFiles);
      data.append('file', part, part.name);
      return data;
    }

    const fetchData = async () => {
      const inputImportedDocs = await createFormdataImportedDocuments(files);
      if (inputImportedDocs.has('file')) {
        await postImportedDocuments(inputImportedDocs);
        if (errorImportedDocuments) {
          setErrorMsg(errorImportedDocuments.message);
        }
      } else {
        setImportedDone(true);
      }
      const inputSupportingDocs = await createFormdataSupportingDocuments(
        files,
        emlFiles,
      );
      if (inputSupportingDocs.has('file')) {
        await postSupportingDocuments(inputSupportingDocs);
        if (errorSupportingDocuments) {
          setErrorMsg(errorSupportingDocuments.message);
        }
      } else {
        setSupportingDone(true);
      }
    };

    if (project) {
      if (files?.length > 0) {
        void fetchData();
      } else {
        setImportedDone(true);
        setSupportingDone(true);
      }
      setProjectLink(
        APP_BASE_URL() + `/projects/${project.id}?organization=${orgId}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const handleCreateDraft = async () => {
    // reroute with params
    const p = await draftFetch.post({
      isDraftIncomplete: true,
      externalReferenceSource: 'outlook',
      externalReferenceId: conversationId,
    });
    if (!draftFetch.loading) {
      setProject(p);
    }
  };

  useEffect(() => {
    if (!importedDone) {
      setImportedDone(importedDocumentsResponse.status ? true : false);
    }
    if (!supportingDone) {
      setSupportingDone(supportingDocsResponse.status ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedDocumentsResponse.status, supportingDocsResponse.status]);

  useEffect(() => {
    if (!projectLink || !importedDone || !supportingDone) return;
    params.updateLoading(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectLink, importedDone, supportingDone]);

  useEffect(() => {
    void handleCreateDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const intl = useIntl();

  return (
    <>
      {(!project || !importedDone || !supportingDone) && (
        <Stack
          tokens={{ childrenGap: 20 }}
          style={{ marginTop: 20 }}
          verticalFill>
          <Stack.Item>
            <Spinner
              size={SpinnerSize.large}
              label={intl.formatMessage(messages.draftingProject)}
            />
          </Stack.Item>
        </Stack>
      )}
      {project && importedDone && supportingDone && (
        <Stack tokens={{ childrenGap: 20 }} verticalFill>
          <Stack.Item>
            <p>{intl.formatMessage(messages.uploadDocumentsSuccess)}</p>
          </Stack.Item>
          <Stack.Item>
            <Link target="_blank" href={projectLink}>
              {intl.formatMessage(messages.openProject, {
                title: project.title,
              })}
            </Link>
          </Stack.Item>
        </Stack>
      )}
      {errorImportedDocuments && errorMsg && (
        <>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            //onDismiss={p.resetChoice}
            dismissButtonAriaLabel={intl.formatMessage(messages.close)}>
            <p>{intl.formatMessage(messages.failedToUploadDocuments)}</p>
            {errorMsg}
          </MessageBar>
        </>
      )}
    </>
  );
};
