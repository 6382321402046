import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  newProjectHeader: {
    defaultMessage: 'Create a new project',
  },
  goBack: {
    defaultMessage: 'Go back',
  },
});
