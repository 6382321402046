import { defineMessages } from 'react-intl';

export default defineMessages({
  connecting: { defaultMessage: 'Connecting to Precisely...' },
  startBySelectingTemplate: {
    defaultMessage:
      'To draft contracts in Precisely, start by selecting a template to create a project. Then select files to upload to the project.',
  },
  learnMore: {
    defaultMessage: 'Learn more about Precisely + Outlook integration',
  },
  openAnEmailToStart: {
    defaultMessage:
      'To draft contracts using the Precisely add-in, open an email to begin.',
  },
  createProject: { defaultMessage: 'Create project in Precisely' },
  reset: { defaultMessage: 'Reset' },
  creatingProject: { defaultMessage: 'Creating project' },
  grantAccess: { defaultMessage: 'Grant access' },
  emailLabel: { defaultMessage: 'Email' },
  passwordLabel: { defaultMessage: 'Password' },
  loginButton: { defaultMessage: 'Login' },
  failedToLogin: { defaultMessage: 'Failed to login' },
  requestAccess: { defaultMessage: 'Request for access to Precisely' },
  appAccessInfo: {
    defaultMessage: 'Once connected this app will have access to Precisely',
  },
  showPassword: { defaultMessage: 'Show password' },
  loadingAttachments: { defaultMessage: 'Loading attachments' },
  close: { defaultMessage: 'Close' },
});
