import { TemplateSelect } from '../../../features/templates/TemplateSelect';
import { useCallback, useEffect, useState } from 'react';
import {
  OrganizationOption,
  OrganizationSelect,
} from '../../../features/organizations';
import { Link, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { DocumentsCheckboxList } from '../../../features/documents/DocumentsCheckboxList';
import { TemplateOption } from '../../../features/templates';
import {
  useDocumentsStore,
  useEmlStore,
} from '../../../features/documents/store';
import { useOfficeStore } from '../../../officeStore';
import { useIntl } from 'react-intl';
import messages from '../../messages';
import { useAuthenticationStore } from '../../../app/store';
import { useStateRouterStore } from '../../../app/stateRouter/useStateRouterStore';

export const NewProjectPage = () => {
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationOption>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateOption>();
  const { clearSelection } = useDocumentsStore();
  const { clear: clearEMLFiles } = useEmlStore();

  const { setCurrentRoute } = useStateRouterStore();

  const [_, setDraftProject] = useState<boolean>(false);

  const { conversationId } = useOfficeStore();

  const { officeToken } = useAuthenticationStore();

  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  const intl = useIntl();

  useEffect(() => {
    setDraftProject(false);
    clearSelection();
    clearEMLFiles();
  }, [clearEMLFiles, clearSelection, conversationId]);

  const handleUpdateLoading = useCallback((loadingFiles: boolean) => {
    setLoadingFiles(loadingFiles);
  }, []);

  return (
    <>
      <p>
        {intl.formatMessage(messages.startBySelectingTemplate)}{' '}
        <Link
          target="_blank"
          href="https://help.precisely.se/en/precisely-microsoft-outlook-e-mail-integration">
          {intl.formatMessage(messages.learnMore)}
        </Link>
      </p>

      <Stack
        tokens={{ childrenGap: 20 }}
        style={{
          marginBottom: '1em',
        }}>
        <Stack.Item>
          <OrganizationSelect
            onChange={(_, organization) => {
              setSelectedOrganization(organization);
            }}
            selected={selectedOrganization}
          />
        </Stack.Item>
        <Stack.Item>
          <TemplateSelect
            organizationId={selectedOrganization?.key}
            isDisabled={!selectedOrganization}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
          />
        </Stack.Item>
        <Stack.Item styles={{ root: { flexGrow: 1 } }}>
          {
            <DocumentsCheckboxList
              token={officeToken}
              conversationId={conversationId}
              updateLoading={handleUpdateLoading}
            />
          }
          {loadingFiles && (
            <Spinner
              size={SpinnerSize.large}
              label={intl.formatMessage(messages.loadingAttachments)}
            />
          )}
        </Stack.Item>
      </Stack>
      <PrimaryButton
        data-testid="create-project"
        disabled={!selectedOrganization || !selectedTemplate || loadingFiles}
        onClick={() => {
          setCurrentRoute('/projects/new/success', {
            templateId: selectedTemplate.key,
            organizationId: selectedOrganization.key,
          });
        }}
        style={{
          marginTop: 'auto',
        }}>
        {intl.formatMessage(messages.createProject)}
      </PrimaryButton>
    </>
  );
};
