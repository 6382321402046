import { useEffect, useState } from 'react';
import { Button } from '@fluentui/react-components';
import useFetch, { CachePolicies } from 'use-http';
import { API_BASE_URL, KVASIR_BASE_URL } from '../config';
import { Stack } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { storeValue } from '../utils/storage';
import { MessageBar, MessageBarType } from '@fluentui/react';
import messages from './messages';
import { useIntl } from 'react-intl';

interface User {
  is_provisioned: boolean;
  redirect_url: string;
}

export const Login = (): JSX.Element => {
  const { post: postAuthenticate } = useFetch<
    | {
      accessToken: string;
    }
    | {
      default_message: string;
    }
  >(API_BASE_URL() + '/authenticate');
  const searchParams = new URL(window.location.href).searchParams;
  const email = searchParams.get('email') || '';
  const [password, setPassword] = useState<string>();
  const [token, setToken] = useState<string>();
  const [loginEmail, setLoginEmail] = useState<string>(email);
  const [errorMsg, setErrorMsg] = useState<string>();
  const intl = useIntl();

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLoginEmail(e.target.value);
  }

  const { data, loading } = useFetch<User>(
    KVASIR_BASE_URL() + '/session/user?dest=outlook',
    {
      cachePolicy: CachePolicies.NO_CACHE,
      method: 'post',
      body: { email: email },
    },
    [email],
  );

  const isProvisioned = data?.is_provisioned;
  const redirectUrl = data?.redirect_url;

  useEffect(() => {
    const newToken = searchParams.get('token') || '';
    if (newToken) {
      setToken(newToken);
      Office.context.ui.messageParent(newToken);
    }
  }, [searchParams]);

  function _loginClicked(): void {
    setErrorMsg('');
    void postAuthenticate({ userName: loginEmail, password: password }).then(
      resp => {
        if ('accessToken' in resp) {
          setToken(resp.accessToken);
          Office.context.ui.messageParent(resp.accessToken);
        } else {
          setErrorMsg(resp.default_message);
        }
      },
    );
  }

  function _redirectClicked(): void {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  useEffect(() => {
    if (!token) return;
    storeValue('precisely-token', token);
    Office.context.ui.messageParent(token);
  }, [token]);

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <img
          width={128}
          style={{ paddingRight: '1em', marginRight: '1em' }}
          src="/assets/Microsoft_Office_Outlook_(2018-present).svg"></img>
        <img
          width={128}
          style={{ paddingLeft: '1em', marginLeft: '1em' }}
          src="/assets/Precisely_symbol_black.svg"></img>
      </div>
      <h1>{intl.formatMessage(messages.requestAccess)}</h1>
      <p>{intl.formatMessage(messages.appAccessInfo)}</p>
      {!loading && (
        <>
          {isProvisioned && (
            <Stack tokens={{ childrenGap: 20 }} verticalFill>
              <Button
                data-testid="redirect-btn"
                appearance="primary"
                onClick={_redirectClicked}>
                {intl.formatMessage(messages.grantAccess)}
              </Button>
            </Stack>
          )}
          {!isProvisioned && (
            <Stack tokens={{ childrenGap: 20 }} verticalFill>
              <Stack.Item>
                <TextField
                  label={intl.formatMessage(messages.emailLabel)}
                  value={loginEmail}
                  onChange={handleEmailChange}
                />
                <TextField
                  label={intl.formatMessage(messages.passwordLabel)}
                  type="password"
                  canRevealPassword
                  revealPasswordAriaLabel={intl.formatMessage(
                    messages.showPassword,
                  )}
                  onChange={(_, value) => {
                    setPassword(value);
                  }}
                />
              </Stack.Item>
              <Button
                data-testid="login-btn"
                appearance="primary"
                onClick={_loginClicked}>
                {intl.formatMessage(messages.loginButton)}
              </Button>
              {errorMsg && (
                <>
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    //onDismiss={p.resetChoice}
                    dismissButtonAriaLabel={intl.formatMessage(messages.close)}>
                    <p>{intl.formatMessage(messages.failedToLogin)}</p>
                    {errorMsg}
                  </MessageBar>
                </>
              )}
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default Login;
