import {
  Dropdown,
  MessageBar,
  MessageBarType,
  ResponsiveMode,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { useEffect, useMemo } from 'react';
import useFetch from 'use-http';
import { Template, TemplateOption } from '.';
import { API_BASE_URL } from '../../config';
import { useIntl } from 'react-intl';
import messages from './messages';

export const TemplateSelect = ({
  organizationId,
  isDisabled,
  setSelectedTemplate,
  selectedTemplate,
}: {
  organizationId: number | string;
  isDisabled: boolean;
  setSelectedTemplate: (t: TemplateOption) => void;
  selectedTemplate: TemplateOption;
}): JSX.Element => {
  const { data, get, error, loading } = useFetch<Template[]>(
    API_BASE_URL() + `/organizations/${organizationId}/templates`,
    {},
  );
  const intl = useIntl();

  useEffect(() => {
    if (isDisabled || !organizationId) return;

    void get();
  }, [organizationId, isDisabled, get]);

  const templateOptions = useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data
      ? data
          .filter((t: Template) => t.uploadEnabled)
          .map((t: Template) => {
            return {
              key: t.id,
              text: t.title,
              title: t.title,
            };
          })
          .sort((a, b) => {
            return a.text > b.text ? 1 : -1;
          })
      : [];
  }, [data]);

  const handleChange = (
    e: React.FormEvent<HTMLDivElement>,
    templateOption: TemplateOption,
  ) => {
    setSelectedTemplate(templateOption);
  };

  return (
    <>
      {loading && (
        <Spinner
          size={SpinnerSize.large}
          label={intl.formatMessage(messages.loadingTemplates)}
        />
      )}
      {!error && (
      <Dropdown
        options={templateOptions}
        placeholder={
          organizationId && templateOptions.length == 0
            ? intl.formatMessage(messages.noTemplateWithUploads)
            : intl.formatMessage(messages.selectTemplate)
        }
        label={intl.formatMessage(messages.selectTemplate)}
        disabled={isDisabled}
        onChange={handleChange}
        selectedKey={selectedTemplate?.key}
        responsiveMode={ResponsiveMode.large}
        calloutProps={{
          doNotLayer: true,
          calloutMaxHeight: 300,
        }}
      />)}
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          //onDismiss={p.resetChoice}
          dismissButtonAriaLabel={intl.formatMessage(messages.close)}>
          <p>{intl.formatMessage(messages.errorLoadingTemplates)}</p>
        </MessageBar>
      )}
    </>
  );
};
