import { defineMessages } from 'react-intl';

export default defineMessages({
  draftingProject: { defaultMessage: 'Drafting project' },
  failedToUploadDocuments: { defaultMessage: 'Failed to upload documents' },
  uploadDocumentsSuccess: {
    defaultMessage:
      'The project was created and the files were uploaded successfully.',
  },
  openProject: { defaultMessage: 'Open project: {title}' },
  close: { defaultMessage: 'Close' },
});
