import { Checkbox, Label, Link, Separator, Stack } from '@fluentui/react';
import { useAttachmentDocuments } from './hooks';
import { useDocumentsStore } from './store';
import { useCallback, useMemo } from 'react';
import messages from './messages';
import { useIntl } from 'react-intl';

type DocumentsCheckboxListProps = {
  token: string;
  conversationId: string;
  updateLoading: (loading: boolean) => void;
};

export const DocumentsCheckboxList = ({
  token,
  conversationId,
  updateLoading,
}: DocumentsCheckboxListProps) => {
  const { setDocumentSelected } = useDocumentsStore();
  const { attachmentDocuments: documents } = useAttachmentDocuments({
    token,
    conversationId,
    updateLoading,
  });

  const onChange = useCallback(
    (ev: React.FormEvent<HTMLElement>, checked: boolean) => {
      const target = ev.target as HTMLInputElement;
      setDocumentSelected(target.id, checked);
    },
    [setDocumentSelected],
  );

  const sortedDocuments = useMemo(() => {
    return [...documents].sort(x => {
      return x.attachment ? -1 : 1;
    });
  }, [documents]);

  const intl = useIntl();

  return (
    <>
      <Label style={{ marginBottom: 4 }}>
        {intl.formatMessage(messages.selectDocuments)}
      </Label>
      <Stack
        styles={{
          root: {
            marginTop: '0px !important',
          },
        }}
        tokens={{ childrenGap: 10 }}>
        {sortedDocuments.map(d => (
          <div key={d.id}>
            {!d.attachment && sortedDocuments.length > 1 && <Separator />}
            <Checkbox
              styles={{ label: { alignItems: 'top' } }}
              key={d.id}
              id={d.id.toString()}
              label={d.title}
              onChange={onChange}
              checked={d.isSelected}
              disabled={d.invalidFiletype}
              title={
                d.invalidFiletype
                  ? intl.formatMessage(messages.invalidFileType)
                  : ''
              }
              onRenderLabel={() => {
                if (!d.attachment) {
                  return (
                    <span>
                      {d.title} <br />
                      <Link
                        href="https://help.precisely.se/en/what-is-.eml-file"
                        target="_blank">
                        {intl.formatMessage(messages.emlFileLink)}
                      </Link>
                    </span>
                  );
                }
                if (d.invalidFiletype) {
                  return <s>{d.title}</s>;
                }
                return <span>{d.title}</span>;
              }}
            />
          </div>
        ))}
      </Stack>
    </>
  );
};
