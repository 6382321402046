import { defineMessages } from 'react-intl';

export default defineMessages({
  selectDocuments: { defaultMessage: 'Select documents' },
  invalidFileType: { defaultMessage: 'invalid filetype' },
  emlFileLink: {
    defaultMessage:
      'Upload this email thread as EML files under “Supporting files” in the project',
  },
  uploadEmailAsZipEml: {
    defaultMessage: 'Upload this email thread as zipped EML files',
  },
});
