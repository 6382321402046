import { FormEvent, useEffect } from 'react';
import useFetch from 'use-http';
import type { Organization, OrganizationOption } from '.';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { API_BASE_URL } from '../../config';
import {
  MessageBar,
  MessageBarType,
  ResponsiveMode,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { useFetchOptions } from '../../hooks/useFetchOptions';

export const OrganizationSelect = ({
  onChange,
  selected,
}: {
  onChange: (e: FormEvent<HTMLDivElement>, o: OrganizationOption) => void;
  selected: OrganizationOption;
}): JSX.Element => {
  const {
    loading,
    error,
    data = [],
  } = useFetch<Organization[] | null>(
    API_BASE_URL() + '/organizations',
    {},
    [],
  );

  useEffect(() => {
    if (data && data.length == 1 && !selected) {
      onChange(null, {
        key: data[0].id,
        text: data[0].name,
        title: data[0].name,
      });
    }
  }, [data, onChange, selected]);

  const intl = useIntl();

  return (
    <>
      {loading && (
        <Spinner
          label={intl.formatMessage(messages.loading)}
          size={SpinnerSize.large}
        />
      )}
      {!error && (
        <Dropdown
          options={data
            ?.map((o: Organization) => {
              return {
                key: o.id,
                text: o.name,
                title: o.name,
              };
            })
            .sort(x => {
              return x.text ? 1 : -1;
            })}
          placeholder={intl.formatMessage(messages.select)}
          label={intl.formatMessage(messages.select)}
          onChange={onChange}
          selectedKey={selected?.key}
          calloutProps={{
            doNotLayer: true,
            calloutMaxHeight: 300,
          }}
          notifyOnReselect={true}
          responsiveMode={ResponsiveMode.large}
        />
      )}
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          //onDismiss={p.resetChoice}
          dismissButtonAriaLabel={intl.formatMessage(messages.close)}>
          <p>{intl.formatMessage(messages.error)}</p>
        </MessageBar>
      )}
    </>
  );
};
