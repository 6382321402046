import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import { IconButton, IIconProps } from '@fluentui/react';
import { makeStyles } from '@fluentui/react-components';
import { useNavState } from './NavState';
import { useStateRouterStore } from './stateRouter/useStateRouterStore';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    borderBottom: '1px solid #eaeaea',
  },
  heading: {
    fontSize: '1rem',
    lineHeight: '1.3rem',
    fontWeight: 600,
  },
  icon: {
    marginRight: '8px',
  },
});

type HeaderProps = {
  showNav: boolean;
  onBack: () => void | null;
  heading?: string;
};

const chevronLeftIcon: IIconProps = { iconName: 'ChevronLeft' };

export const NavHeader = () => {
  const { currentRoute: location, setCurrentRoute } = useStateRouterStore();
  const intl = useIntl();
  const { backDisabled, reset } = useNavState();

  useEffect(() => {
    reset();
  }, [location, reset]);

  const { showNav, onBack, heading } = useMemo<HeaderProps>(() => {
    switch (location) {
      case '/projects/new':
        return {
          showNav: true,
          onBack: null,
          heading: intl.formatMessage(messages.newProjectHeader),
        };
      case '/projects/new/success': {
        return {
          showNav: true,
          onBack: () => setCurrentRoute('/projects/new'),
          heading: intl.formatMessage(messages.newProjectHeader),
        };
      }
      default: {
        return {
          showNav: false,
          onBack: null,
          heading: '',
        };
      }
    }
  }, [location, intl, setCurrentRoute]);

  const styles = useStyles();

  return (
    <>
      {showNav && (
        <div className={styles.root}>
          {onBack && (
            <IconButton
              iconProps={chevronLeftIcon}
              onClick={onBack}
              title={intl.formatMessage(messages.goBack)}
              disabled={backDisabled}
              className={styles.icon}>
              {intl.formatMessage(messages.goBack)}
            </IconButton>
          )}
          <h1 className={styles.heading}>{heading}</h1>
        </div>
      )}
    </>
  );
};
